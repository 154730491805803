<template>
  <q-layout view="hHr LpR lFr">
    <CustomDrawer />
    <q-page-container class="page-container">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import CustomDrawer from '@/components/customDrawer/CustomDrawer.vue'
import { useNotifyStore } from '@/stores/notify'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useTypesStore } from './stores/types'

export default defineComponent({
  name: 'App',
  components: { CustomDrawer },
  watch: {
    getError(val) {
      if (val.length > 0) {
        this.$q.notify({
          type: 'negative',
          message: this.getError,
        })
        useNotifyStore().fetchError('')
      }
    },
    getSuccess(val) {
      if (val.length > 0) {
        this.$q.notify({
          type: 'positive',
          message: this.getSuccess,
        })
        useNotifyStore().fetchSuccess('')
      }
    },
  },
  computed: {
    ...mapState(useNotifyStore, {
      getError: 'error',
      getSuccess: 'success',
    }),
  },
  async created() {
    const useStoreTypes = useTypesStore()
    useStoreTypes.fetchAllContractorTypes()
    useStoreTypes.fetchAllAddressTypes()
    useStoreTypes.fetchAllContactTypes()
    useStoreTypes.fetchAllStatusTypes()
  },
})
</script>
<style lang="scss" scoped>
.page-container {
  background: #f6fafd !important;
  overflow: hidden;
  min-height: 92vh;
}
</style>
